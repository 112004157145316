import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ApiService, environmentToken } from '@mca/shared/util';
import { RxState } from '@rx-angular/state';
import { minutesToMilliseconds } from 'date-fns';
import { MessageService } from 'primeng/api';
import { catchError, EMPTY, filter, interval, map, Observable, of, switchMap, throwError } from 'rxjs';
import { LiveMessageList, LiveMessageNew, LiveMessagePost } from '../../entities/live-messages';
import { httpSystemLiveMessages, httpSystemNewLiveMessage } from '../../infrastructure/system-http-endpoints';
import { WebsocketService } from './websocket.service';

interface State {
  newMessage: LiveMessageNew;
}

@Injectable({
  providedIn: 'root',
})
export class LiveMessagesService extends RxState<State> {
  private apiService = inject(ApiService);
  private messageService = inject(MessageService);
  private websocketService = inject(WebsocketService);
  env = inject(environmentToken);

  get newMessageData$() {
    return this.select('newMessage');
  }

  get lastId() {
    return this.get('newMessage')?.lastId ?? 0;
  }

  constructor() {
    super();
    this.notifyNewMessages();
    if (this.env.websocketUrl) {
      this.listenWebsocket();
    } else {
      this.startPolling();
    }
  }

  sendLiveMessage(data: LiveMessagePost) {
    return this.apiService.post(httpSystemLiveMessages(), data);
  }

  getLiveMessages() {
    return this.apiService.get<LiveMessageList>(httpSystemLiveMessages());
  }

  getNewLiveMessage() {
    return this.apiService.get<LiveMessageNew>(httpSystemNewLiveMessage(this.lastId)).pipe(
      catchError((e: HttpErrorResponse) => {
        if (e.status === 304) {
          return of(this.get('newMessage'));
        }
        return throwError(() => e);
      }),
    );
  }

  // call in constructor to switch to http polling if websocket does not work
  private startPolling() {
    const delay = minutesToMilliseconds(1);
    const newMessagePoll$: Observable<LiveMessageNew> = interval(delay).pipe(
      switchMap(() => this.getNewLiveMessage().pipe(catchError(() => EMPTY))),
    );
    this.connect('newMessage', newMessagePoll$);
  }

  private notifyNewMessages() {
    this.hold(this.newMessageData$.pipe(filter(v => !!v.lastMessage && !!v.lastMessage.message)), messageData => {
      this.messageService.add({ severity: 'info', summary: 'New message', detail: messageData.lastMessage?.message });
    });
  }

  private listenWebsocket() {
    this.connect(
      'newMessage',
      this.websocketService.listen().pipe(
        filter(message => message.messageType === 'chat'),
        map(message => message.body),
      ),
    );
  }
}
