import { Injectable, inject } from '@angular/core';
import { WebsocketBridge } from '../../entities/websocket';

@Injectable({
  providedIn: 'root',
})
export class WebsocketService implements WebsocketBridge {
  private websocketBridge = inject(WebsocketBridge);

  listen() {
    return this.websocketBridge.listen();
  }

  post(data: any) {
    this.websocketBridge.post(data);
  }
}
