export enum BECommandAction {
  logout = 'logout',
}

export interface BECommandData {
  action: BECommandAction;
  message: string;
}

export interface BECommand {
  execute(data: BECommandData): void;
}
