import { Injectable, inject } from '@angular/core';
import { RxState } from '@rx-angular/state';
import { filter, map, Subject } from 'rxjs';
import { WebsocketService } from './websocket.service';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { DialogService } from '@mca/shared/feature-dialog';
import { environmentToken } from '@mca/shared/util';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService extends RxState<never> {
  private websocketService = inject(WebsocketService);
  private dialogService = inject(DialogService);
  private env = inject(environmentToken);

  private closeDialog = new Subject<boolean>();
  closeDialog$ = this.closeDialog.asObservable();
  channel = new BroadcastChannel('command');

  watchMessages() {
    if (!this.env.websocketUrl) {
      return;
    }
    this.hold(
      this.websocketService.listen().pipe(
        filter(message => message.messageType === 'notification'),
        map(data => data.body.lastMessage.message),
      ),
      message => {
        this.dialogService
          .confirm({
            title: 'Notification',
            message,
            confirmLabel: 'OK',
            cancelLabel: '',
          })
          .subscribe(() => {
            this.dialogService.closeModal();
            this.channel.postMessage('Notification closed');
            this.closeDialog.next(true);
          });
      },
    );
  }
}
