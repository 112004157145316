import { Injectable, inject } from '@angular/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { DialogService } from '@mca/shared/feature-dialog';

@Injectable({
  providedIn: 'root',
})
export class ClientCommandService {
  private dialogService = inject(DialogService);

  channel = new BroadcastChannel('command');
  watchMessages() {
    this.channel.addEventListener('message', event => {
      if (event.data === 'Notification closed') {
        this.dialogService.closeModal(undefined, this.dialogService.confirmDialogReference);
      }
    });
  }
}
