import { Injectable, Injector, inject } from '@angular/core';
import { RxState } from '@rx-angular/state';
import { filter, map } from 'rxjs';
import { WebsocketService } from './websocket.service';
import { environmentToken } from '@mca/shared/util';
import { BECommand, BECommandAction, BECommandData } from '../../entities/be-command';
import { WebsocketMessage } from '../../entities/websocket';
import { BECommandLogoutService } from './be-command-logout.service';

type CommandConstructor = new (...args: any[]) => BECommand;

const commandMap: Record<BECommandAction, CommandConstructor> = {
  logout: BECommandLogoutService,
};

@Injectable({
  providedIn: 'root',
})
export class BECommandService extends RxState<never> {
  private websocketService = inject(WebsocketService);
  private env = inject(environmentToken);

  watchMessages() {
    if (!this.env.websocketUrl) {
      return;
    }
    this.hold(
      this.websocketService.listen().pipe(
        filter((message): message is WebsocketMessage<BECommandData> => message.messageType === 'command'),
        map(data => data.body),
      ),
      command => {
        inject(Injector).get(commandMap[command.action]).execute(command);
      },
    );
  }
}
