import { Injectable, inject } from '@angular/core';
import { AuthService } from '@mca/auth/api';
import { environmentToken, generateUuid } from '@mca/shared/util';
import { Subject } from 'rxjs';
import { WebsocketBridge, WebsocketMessage } from '../../entities/websocket';

@Injectable()
export class WebsocketWorkerService implements WebsocketBridge {
  env = inject(environmentToken);
  private tabId = generateUuid();
  private worker = new SharedWorker('../infrastructure/websocket-worker', { type: 'module' });
  private webSocketState = WebSocket.CONNECTING;
  private broadcastChannel = new BroadcastChannel('WebSocketChannel');
  private authService = inject(AuthService);

  private messages$ = new Subject<WebsocketMessage>();

  constructor() {
    this.listenPort();
    this.listenBroadcast();
    this.postMessage({ url: this.env.websocketUrl, token: this.authService.token }, 'CONNECT');
  }

  listen() {
    return this.messages$.asObservable();
  }

  post(data: any) {
    this.postMessage(data);
  }

  private listenPort() {
    this.worker.port.start();
    this.worker.port.onmessage = event => {
      switch (event.data.type) {
        case 'WSState':
          this.webSocketState = event.data.state;
          break;
        case 'message':
          this.handleMessageFromPort(event.data);
          break;
      }
    };
  }

  private listenBroadcast() {
    this.broadcastChannel.addEventListener('message', event => {
      switch (event.data.type) {
        case 'WSState':
          this.webSocketState = event.data.state;
          break;
        case 'message':
          this.handleBroadcast(event.data);
          break;
      }
    });
  }

  private handleMessageFromPort(data: any) {
    console.log(`Port message`);
    console.log(data);
    this.messages$.next(data);
  }

  private handleBroadcast(data: any) {
    console.log('Broadcast message');
    console.log(data);
    this.messages$.next(data);
  }

  private postMessage(input: any, type = 'MESSAGE') {
    if (this.webSocketState === WebSocket.CONNECTING) {
      console.log('Still connecting to the server...');
    } else if (this.webSocketState === WebSocket.CLOSING || this.webSocketState === WebSocket.CLOSED) {
      console.log('Connection closed');
    } else {
      this.worker.port.postMessage({
        from: this.tabId,
        type,
        data: input,
      });
    }
  }
}
