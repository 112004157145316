export const httpSystemEmails = () => 'systool/emails';
export const httpSystemComponents = () => 'systool/job';
export const httpSystemLogs = () => 'systool/log';
export const httpSystemClearCache = () => 'systool/clearcache';
export const httpSystemACHScheduled = () => 'systool/achscheduled';
export const httpSystemTransAllocate = () => 'systool/transallocator';
export const httpSystemBPAllocate = () => 'systool/bpalloc';
export const httpSystemPortfolioSnap = () => 'systool/portfoliosnap';
export const httpSystemMcaAccess = () => `systool/mcaaccess`;
export const httpSystemEmailConfig = () => `systool/emlconfig`;
export const httpSystemUpdateEmailConfig = (tag: string) => `systool/emlconfig/${tag}`;
export const httpSystemLiveMessages = () => `systool/messages`;
export const httpSystemNewLiveMessage = (lastId: number) => `systool/messages/new/${lastId}`;
export const httpSystemChangelog = (module: string, recid: number) => `systool/changelog/${module}/${recid}`;
export const httpSystemChangelogAggregated = () => 'systool/changelog/aggregated';
export const httpSystemConfiguration = () => `systool/system_config`;
