import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@mca/auth/domain';
import { MessageService } from 'primeng/api';
import { BECommand, BECommandData } from '../../entities/be-command';

@Injectable({
  providedIn: 'root',
})
export class BECommandLogoutService implements BECommand {
  private authService = inject(AuthService);
  private messageService = inject(MessageService);
  private router = inject(Router);

  execute(data: BECommandData) {
    this.messageService.add({ severity: 'warn', summary: data.message });
    this.authService.cleanSession();
    this.router.navigate(['/login']);
  }
}
