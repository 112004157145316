import { Injectable, inject } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { AuthService } from '@mca/auth/domain';
import { WebsocketBridge, WebsocketMessage } from '../../entities/websocket';
import { environmentToken } from '@mca/shared/util';
import { tap, map, finalize, catchError, EMPTY, filter, switchMap, distinctUntilChanged, BehaviorSubject, combineLatest } from 'rxjs';
import { RxState } from '@rx-angular/state';
import { secondsToMilliseconds } from 'date-fns';

interface State {
  message: WebsocketMessage;
  connection: WebSocketSubject<string> | null;
  retryTimeoutSeconds: number;
}

@Injectable()
export class WebsocketRxjsService extends RxState<State> implements WebsocketBridge {
  private authService = inject(AuthService);
  private env = inject(environmentToken);
  private retry$ = new BehaviorSubject<void>(undefined);

  constructor() {
    super();
    this.set({ retryTimeoutSeconds: 5 });
    this.initConnection();
    this.handleConnection();
  }

  listen() {
    return this.select('message').pipe(tap(message => console.log('Websocket message: ' + JSON.stringify(message))));
  }

  post(data: any) {
    this.get('connection')?.next(data);
  }

  private initConnection() {
    if (!this.env.websocketUrl) {
      return;
    }
    const connection$ = combineLatest([
      this.authService.currentUser$.pipe(distinctUntilChanged((prev, next) => prev?.id === next?.id)),
      this.retry$,
    ]).pipe(
      tap(() => this.closeConnection()),
      map(([user]) => {
        if (user) {
          return webSocket<string>(this.env.websocketUrl + '?token=' + this.authService.token);
        }
        this.set({ retryTimeoutSeconds: 5 });
        return null;
      }),
    );
    this.connect('connection', connection$);
  }

  private handleConnection() {
    this.hold(
      this.select('connection').pipe(
        filter((v): v is WebSocketSubject<string> => v !== null),
        switchMap(v =>
          v.pipe(
            catchError(() => EMPTY),
            finalize(() => {
              console.log('Websocket: connection closed');
              this.closeConnection();
              this.retryConnection();
            }),
          ),
        ),
      ),
      message => this.set({ message: JSON.parse(message) as WebsocketMessage, retryTimeoutSeconds: 5 }),
    );
  }

  private retryConnection() {
    setTimeout(
      () => {
        if (!this.authService.currentUser) {
          return;
        }
        console.log('Websocket: retrying connection');
        this.set({ retryTimeoutSeconds: this.get('retryTimeoutSeconds') * 2 });
        this.retry$.next();
      },
      secondsToMilliseconds(this.get('retryTimeoutSeconds')),
    );
  }

  private closeConnection() {
    const connection = this.get('connection');
    if (connection) {
      connection.complete();
    }
  }
}
