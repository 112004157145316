import { Injectable, inject } from '@angular/core';
import { ApiService } from '@mca/shared/util';
import {
  httpSystemEmails,
  httpSystemComponents,
  httpSystemLogs,
  httpSystemClearCache,
  httpSystemACHScheduled,
  httpSystemTransAllocate,
  httpSystemPortfolioSnap,
  httpSystemMcaAccess,
  httpSystemEmailConfig,
  httpSystemUpdateEmailConfig,
  httpSystemChangelog,
  httpSystemConfiguration,
  httpSystemBPAllocate,
  httpSystemChangelogAggregated,
} from '../infrastructure/system-http-endpoints';
import { Observable } from 'rxjs';
import { EmailConfig } from '../entities/email-config';
import { SystemConfig } from '@mca/shared/domain';
import { ChangelogRec } from '../entities/changelog';

@Injectable({
  providedIn: 'root',
})
export class SystemService {
  private apiService = inject(ApiService);

  getSystemEmails(status: string, startDate: string, endDate: string) {
    return this.apiService.get(httpSystemEmails(), {
      params: {
        status,
        start: startDate,
        end: endDate,
      },
    });
  }

  deleteSystemEmails(ids: string[]) {
    return this.apiService.delete(httpSystemEmails(), {
      params: {
        ids,
      },
    });
  }

  getSystemComponents() {
    return this.apiService.get(httpSystemComponents());
  }

  getSystemLog(component: string, severity: string, startDate: string, endDate: string) {
    return this.apiService.get(httpSystemLogs(), {
      params: {
        comp: component,
        level: severity,
        startDate,
        endDate,
      },
    });
  }

  deleteSystemLog(ids: number[]) {
    return this.apiService.delete(httpSystemLogs(), {
      params: {
        ids,
      },
    });
  }

  clearCache(): void {
    this.apiService.post(httpSystemClearCache()).subscribe(() => location.reload());
  }

  achScheduled(): void {
    this.apiService.post(httpSystemACHScheduled()).subscribe(() => location.reload());
  }

  allocateTransactions(): void {
    this.apiService.post(httpSystemTransAllocate()).subscribe(() => location.reload());
  }

  allocateBPAllocate(): void {
    this.apiService.post(httpSystemBPAllocate()).subscribe(() => location.reload());
  }

  portfolioSnapp(): void {
    this.apiService.post(httpSystemPortfolioSnap()).subscribe(() => location.reload());
  }

  mcaAccess(params: { mcaid: number; user: number; startDate: string; endDate: string }) {
    return this.apiService.get(httpSystemMcaAccess(), { params });
  }

  getSystemEmailConfig(): Observable<EmailConfig[]> {
    return this.apiService.get(httpSystemEmailConfig());
  }

  updateSystemEmailConfig(tag: string, params: EmailConfig) {
    return this.apiService.put(httpSystemUpdateEmailConfig(tag), params);
  }

  getChangelog(module: string, recId: number) {
    return this.apiService.get<ChangelogRec[]>(httpSystemChangelog(module, recId));
  }

  getChangelogAggregated(params: { rec_type: string[]; start_date: string; end_date: string }) {
    return this.apiService.get<ChangelogRec[]>(httpSystemChangelogAggregated(), { params });
  }

  getConfiguration() {
    return this.apiService.get<Record<keyof SystemConfig, string>>(httpSystemConfiguration());
  }

  updateConfiguration(params: Record<keyof SystemConfig, string>) {
    return this.apiService.put(httpSystemConfiguration(), params);
  }
}
