import { Observable } from 'rxjs';
import { LiveMessageType } from './live-messages';

export type WorkerMessageTypes = 'CONNECT' | 'MESSAGE';

export interface WebsocketWorkerMessage {
  from: string;
  type: WorkerMessageTypes;
  data: any;
}

export abstract class WebsocketBridge {
  abstract listen(): Observable<WebsocketMessage>;
  abstract post(data: any): void;
}

export interface WebsocketMessage<T = any> {
  messageType: LiveMessageType;
  body: T;
}
