export interface LiveMessage {
  id: number;
  author: number;
  update_ts: string;
  message: string;
  target_type: LiveMessageTargetType;
  target: number[];
}

export interface LiveMessageList {
  lastViewedId: number;
  messages: LiveMessage[];
}

export interface LiveMessageNew {
  total: number;
  lastId: number;
  lastMessage: LiveMessage | null;
}

export enum LiveMessageType {
  chat = 'chat',
  notification = 'notification',
  command = 'command',
}

export const messagePermissions = {
  [LiveMessageType.chat]: null,
  [LiveMessageType.notification]: 'messages_create_notification',
  [LiveMessageType.command]: 'messages_create_command',
};

export enum LiveMessageTargetType {
  'All' = 0,
  'Users' = 1,
  'Roles' = 2,
}

export interface LiveMessagePost {
  messageType: LiveMessageType;
  targetType: LiveMessageTargetType;
  targets: number[];
  message: string;
}
